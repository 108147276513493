import React, { Component } from 'react'
import { Link } from 'gatsby'

export class Navbar extends Component {
	constructor(props) {
		super(props)

		this.onClickNothing = this.onClickNothing.bind(this)
	}

	onClickNothing = e => {
		e.preventDefault()
	}

	render() {
		return (
			<nav className="main-nav">
				<ul className="level-1">
					<li className="level-1-item">
						<Link className="nav-link" to="/">
							Home
						</Link>
					</li>

					<li className="level-1-item dropdown-parent">
						<span className="nav-link-null">Work</span>

						<ul className="level-2 dropdown">
							<li className="level-2-item">
								<Link className="nav-link" to="/work/rowe-dynamics-site">
									Rowe Dynamics
								</Link>
							</li>
							<li className="level-2-item">
								<Link className="nav-link" to="/work/spinal-cord-stim-site">
									Spinal Cord Stim
								</Link>
							</li>
							<li className="level-2-item">
								<Link className="nav-link" to="/work/make-my-deal-site">
									Make My Deal
								</Link>
							</li>
						</ul>
					</li>
					<li className="level-1-item" onClick={this.props.handleClick}>
						<span className="nav-link-null">Contact</span>
					</li>
				</ul>
			</nav>
		)
	}
}

export default Navbar
