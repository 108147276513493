import React, { Component } from 'react'
import Navbar from './Navbar'
import styled from 'styled-components'
import SiteTitleCard from './SiteTitleCard'

export class HeaderMain extends Component {
	constructor(props) {
		super(props)
	}
	render() {
		return (
			<Header className="header main-header">
				<div className="bg-banner"></div>
				<Navbar handleClick={this.props.handleClick} />
				<SiteTitleCard />
				<div className="early-alpha-tag">
					<span>This site is in</span>
					<span>Early Alpha</span>
				</div>
			</Header>
		)
	}
}

export default HeaderMain

export const Header = styled.header`
	/* background: hsla(0, 0%, 100%, 0.5); */
`
