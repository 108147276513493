import React, { Component } from 'react'
import HeaderMain from '../HeaderMain'
// import FontStyles from './helpers/FontStyles'
import ReactModal from 'react-modal'
import ContactForm from '../forms/ContactForm'

ReactModal.setAppElement('#___gatsby')

export class LayoutMain extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showModal: false,
		}
		this.handleModalClose = this.handleModalClose.bind(this)
	}

	handleModalClose = () => {
		if (this.state.showModal === true) {
			this.setState({ showModal: false })
		} else {
			this.setState({ showModal: true })
		}
	}

	render() {
		return (
			<div
				className={'page-container ' + (this.props.isIndex ? 'landing-page ' : 'inner-page ') + (this.props.isSuccess ? 'success-page ' : '')}
			>
				<HeaderMain isIndex={this.props.isIndex} handleClick={this.handleModalClose} />
				<div className="gridspace page-content">
					<main>{this.props.children}</main>
					{/* <FontStyles /> */}
				</div>
				<ReactModal
					className="modal form-modal contact-form-modal"
					overlayClassName="modal-overlay form-modal-overlay"
					isOpen={this.state.showModal}
					// onRequestClose={this.handleModalClose}
					shouldCloseOnOverlayClick={false}
				>
					<ContactForm handleClick={this.handleModalClose} />
				</ReactModal>
			</div>
		)
	}
}

export default LayoutMain
