import React, { Component } from 'react'
import Button from '../elements/Button'
import ReactCursorPosition from 'react-cursor-position'
import { IoIosCloseCircle } from 'react-icons/io'

export class ContactForm extends Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<div className="form-wrapper">
				<div className={'form-container contact-form ' + this.props.className}>
					<div onClick={this.props.handleClick} className="close-btn">
						<IoIosCloseCircle />
					</div>
					<h1 className="form-title">Contact</h1>
					<form name="Contact" method="POST" data-netlify="true" action="/success">
						<input type="hidden" name="form-name" value="Contact" />
						<div className="form-row">
							<label>Your Name:</label>
							<input type="text" name="name" />
						</div>
						<div className="form-row">
							<label>Your Email:</label>
							<input type="email" name="email" />
						</div>
						<div className="form-row">
							<label>Phone:</label>
							<input type="tel" name="phone" />
						</div>
						{/* <div className="form-row">
						<label>
							Your Role:{' '}
							<select name="role[]" multiple>
								<option value="leader">Leader</option>
								<option value="follower">Follower</option>
							</select>
						</label>
						</div> */}

						<div className="form-row">
							<label>Message:</label>
							<textarea name="message"></textarea>
						</div>
						<div className="form-row">
							<div className="disclainer">
								The content of this email is strictly confidential. It will never be published and I will never share your information
								with any third party.
							</div>
						</div>
						<div className="form-row center">
							<ReactCursorPosition>
								<Button size="lg" kind="primary" type="submit" text="send" />
							</ReactCursorPosition>
						</div>
					</form>
				</div>
			</div>
		)
	}
}

export default ContactForm
