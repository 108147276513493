import React from 'react'
import Image from 'gatsby-image'
import { GiLotus } from 'react-icons/gi'
import { graphql, useStaticQuery } from 'gatsby'

const getImages = graphql`
	query Images {
		headshot: file(relativePath: { eq: "headshot-2.jpg" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`

const SiteTitleCard = () => {
	const data = useStaticQuery(getImages)

	return (
		<div className="site-title-card">
			<div className="headshot-container">
				{/* <img src="img/headshot-2.jpg" alt="headshot" /> */}
				<Image fluid={data.headshot.childImageSharp.fluid} />
			</div>
			<div className="title-container">
				<div className="main-title">Joshua Burke</div>
				<div className="sub-title">
					<ul>
						<li>Web Developer</li>
						<li>
							<i className="game-icon-lotus">
								<GiLotus />
							</i>
						</li>
						<li>Designer</li>
						<li>
							<i className="game-icon-lotus">
								<GiLotus />
							</i>
						</li>
						<li>Digital Artist</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default SiteTitleCard
