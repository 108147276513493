import React, { Component } from 'react'

// import styled from 'styled-components'

export class Button extends Component {
	constructor(props) {
		super(props)

		this.state = {
			isPressed: false,
			mouseLeft: false,
			mouseCenter: false,
			mouseRight: false,
		}
	}

	handleMouseDownEvent = event => {
		event.preventDefault()
		this.setState(state => ({ isPressed: true }))
	}

	handleMouseUpEvent = event => {
		event.preventDefault()
		this.setState(state => ({ isPressed: false }))
	}

	componentDidUpdate(prevProps) {
		if (this.props.isActive === true) {
			let elemWidth = this.props.elementDimensions.width
			let mousePos = this.props.position.x

			if (mousePos < elemWidth * 0.32) {
				if (this.state.mouseLeft === false) {
					this.setState({
						mouseLeft: true,
						mouseCenter: false,
						mouseRight: false,
					})
				}
			} else if (mousePos > elemWidth * 0.65) {
				if (this.state.mouseRight === false) {
					this.setState({
						mouseLeft: false,
						mouseCenter: false,
						mouseRight: true,
					})
				}
			} else {
				if (this.state.mouseCenter === false) {
					this.setState({
						mouseLeft: false,
						mouseCenter: true,
						mouseRight: false,
					})
				}
			}
		} else {
			if (this.state.mouseLeft === true || this.state.mouseCenter === true || this.state.mouseRight === true) {
				this.setState({
					mouseLeft: false,
					mouseCenter: false,
					mouseRight: false,
				})
			}
		}
	}

	render() {
		return (
			<div
				role="button"
				className={'retro-btn ' + this.props.size + ' ' + this.props.kind}
				onMouseDown={this.handleMouseDownEvent}
				onMouseUp={this.handleMouseUpEvent}
			>
				<button
					className={
						'btn ' +
						(this.state.isPressed ? 'btn-active ' : '') +
						(this.state.mouseLeft ? 'btn-left ' : '') +
						(this.state.mouseCenter ? 'btn-center ' : '') +
						(this.state.mouseRight ? 'btn-right ' : '')
					}
					type={this.props.type}
				>
					<span className="btn-inner">
						<span className="content-wrapper">
							<span className="btn-content">
								<span className="btn-content-inner" label={this.props.text}></span>
							</span>
						</span>
					</span>
				</button>
			</div>
		)
	}
}

export default Button

// export const ButtonLarge = styled.div`
// 	background: green;
// 	color: white;
// 	font-size: 2rem;
// 	display: inline-block;
// `
